
        import V from 'voUtils/V.js';
        import Vue from 'vue';
        import {voMsg,voMsgPl} from './voUtils/voI18n.js';
        import DateEx from './voUtils/DateEx.js';

        Vue.prototype.VOFFICE = window.VOFFICE;

        var voFormatDate = DateEx.formatDate;

        import AirbnbStyleDatepicker from 'base/vue-airbnb-style-datepicker'
        import 'base/vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css'

        var vueApps = {};

        var methods = {voMsg,voMsgPl,voFormatDate};

        
            import App0 from './app/unit.js';
            vueApps['app/unit.js'] = App0;
        

            import App1 from './app/dummy.js';
            vueApps['app/dummy.js'] = App1;
        

            import App2 from './app/search.js';
            vueApps['app/search.js'] = App2;
        

            import App3 from './app/SimilarUnits.js';
            vueApps['app/SimilarUnits.js'] = App3;
        

            import App4 from './app/facility.js';
            vueApps['app/facility.js'] = App4;
        

            import App5 from './app/UnitsOfTheDay.js';
            vueApps['app/UnitsOfTheDay.js'] = App5;
        

            import App6 from './app/navbar.js';
            vueApps['app/navbar.js'] = App6;
        

        
                import Comp7 from './comp/ImgFallback.vue';
                Vue.component('img-fallback',Comp7);
            

                import Comp8 from './comp/Booking.js';
                Vue.component('voffice-booking',Comp8);
            

                import Comp9 from './comp/DocBox.vue';
                Vue.component('voffice-doc-box',Comp9);
            

                import Comp10 from './comp/ErrorMessage.vue';
                Vue.component('voffice-error-message',Comp10);
            

                import Comp11 from './comp/FavElement.vue';
                Vue.component('voffice-fav-element',Comp11);
            

                import Comp12 from './comp/DateRange.vue';
                Vue.component('voffice-daterange',Comp12);
            

                import Comp13 from './comp/GuestBox.vue';
                Vue.component('voffice-guestbox',Comp13);
            

                import Comp14 from './comp/childrenage.vue';
                Vue.component('childrenage',Comp14);
            

                import Comp15 from './comp/MaxTextSize.js';
                Vue.component('voffice-max-text-size',Comp15);
            

                import Comp16 from './comp/PhotoSwipe.js';
                Vue.component('voffice-photoswipe',Comp16);
            

                import Comp17 from './comp/DateRangeExt.vue';
                Vue.component('voffice-daterange-ext',Comp17);
            

                import Comp18 from './videoiframe.vue';
                Vue.component('video-iframe',Comp18);
            

                import Comp19 from './comp/Accordion.vue';
                Vue.component('voffice-accordion',Comp19);
            

                import Comp20 from './comp/CookieBox.vue';
                Vue.component('voffice-cookie-box',Comp20);
            

                import Comp21 from './comp/SearchFilterTokens.vue';
                Vue.component('search-filter-tokens',Comp21);
            

                import Comp22 from './comp/PopupBox.vue';
                Vue.component('voffice-popup-box',Comp22);
            

                import Comp23 from './teamMember.vue';
                Vue.component('voffice-team-member',Comp23);
            

                import Comp24 from './comp/ContactForm.js';
                Vue.component('voffice-form',Comp24);
            

                import Comp25 from './comp/IncreaseNumber.vue';
                Vue.component('increase-number',Comp25);
            

                import Comp26 from './comp/Voucher.vue';
                Vue.component('voffice-voucher',Comp26);
            

                import Comp27 from './comp/PopupDialog.vue';
                Vue.component('voffice-popup-dlg',Comp27);
            

                import Comp28 from './comp/openstreetmap.vue';
                Vue.component('voffice-openstreetmap',Comp28);
            

                import Comp29 from './comp/gmap.js';
                Vue.component('voffice-gmap',Comp29);
            

                import Comp30 from './comp/SearchFilterTokens-v2.vue';
                Vue.component('search-filter-tokens-v2',Comp30);
            

                import Comp31 from './imageCarousel.vue';
                Vue.component('img-carousel',Comp31);
            

                import Comp32 from './offershelp.vue';
                Vue.component('offer-help',Comp32);
            

                import Comp33 from './pricehelp.vue';
                Vue.component('price-help',Comp33);
            

        Vue.mixin({
            methods: methods
        });

        const datepickerOptions = {
            days: voMsg('weekdayslist').split(','),
            daysShort: voMsg('weekdayslist.short').split(','),
            monthNames: voMsg('monthlist.full').split(',')
        };
        Vue.use(AirbnbStyleDatepicker, datepickerOptions);


    	var passThrough = function (val) {
			return val || '';
		};

    	var filterCodes = [
    	    'vlang',
    	    'vautolang',
    	    'vsoption',
    	    'vsPropOption'
    	  ];

        for (let fc of filterCodes) {
    		Vue.filter(fc, passThrough);
    	};


        var vueAppEls = document.body.querySelectorAll('[vo-vue-app]');

        for (let el of vueAppEls) {
            let vName = el.getAttribute("vo-vue-app");
            let v = vueApps[vName];
            if (v) {
                v.el = el;
                V.log("creating vue app",vName);

                let app = new Vue(v);
            }

        }

        VOFFICE.afterAllLoaded = function(){
            document.documentElement.classList.add("app-loaded");
        };

        window.addEventListener('beforeunload', function (event) {
        	document.documentElement.classList.add("app-leaving");
        });


    